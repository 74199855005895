import { IRole } from '../../rights/models/rights.interface';
import { Device } from '../../devices/models/device.model';

export class Agent {
  network: string;
  agent_id: number;
  agent_firstname: string;
  agent_fullname: string;
  agent_lastname: string;
  agent_username: string;
  img_url: string;
  password: string;
  phone: string;
  registration_id: string;
  role: string;
  status: string;
  role_id: number;
  linked_mail: string;
  agent_signature: string;
  created_datetime: any;
  deleted_datetime: any;
  is_active: boolean;
  super_network: string;
  agents_logins: TAgent[];
  devices: Device[];
  lang?: string;
  latitude?: number;
  longitude?: number;
  agent_username_list?: any[];
  rights?: IRole;
  site: string;
  old_img_url?: string;
  last_assign_timestamp?: number;
  force_update_device?: boolean;
  can_update?: boolean;
  is_civilian: boolean;
  main_network: string;
  site_id?: number;

  static agentStatusByIcon = {
    10460: 'assets/agents-icons/Agents_accident.svg',
    10461: 'assets/agents-icons/Agents_join_operation.svg',
    10462: 'assets/agents-icons/Agents_on_break.svg',
    10463: 'assets/agents-icons/Agents_off_duty.svg',
    10464: 'assets/agents-icons/Agents_on_duty.svg',
  };
  static getIconUrl(agent_status_id: number): string {
    return Agent.agentStatusByIcon[agent_status_id];
  }

  static getColumnToFilter() {
    return ['agent_fullname', 'agent_username', 'role_name', 'phone', 'registration_id'];
  }
}

export class TAgent {
  network: string;
  agent_id: number;
  agent_username: string;

  constructor(network: string, agent_id: number, agent_username: string) {
    this.network = network;
    this.agent_id = agent_id;
    this.agent_username = agent_username;
  }
}

export class PatchSignatureAgent {
  agent_id: number;
  agent_username: string;
  network: string;
  agent_signature: string;
  lang?: string;
}
